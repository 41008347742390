import React from "react";
import { Grid } from "@mui/material";

import { FormStyled, Select, FormContext, NumberInput } from "@homesusa/form";
import { AnimatedContainer } from "core/components";
import { useLocalOptions } from "modules/company/hooks";
import { CompanyContext, ServiceFormContext } from "modules/company/contexts";
import { getCorporation } from "core/services/corporations/corporation.service";

import { CompanyServiceCode } from "@homesusa/core";
import { XMLImportFormFields } from "./xml/xml-import-fields.component";
import { CorporationDataForm } from "core/interfaces";

export const CompanyServicesFormFields = (): JSX.Element => {
  const { formState, formErrors, formErrorsDispatch } =
    React.useContext<ServiceFormContext>(FormContext);
  const { company } = React.useContext(CompanyContext);
  const { servicesOptions, frequencyOptions } = useLocalOptions();
  const [corporation, setCorporation] = React.useState<CorporationDataForm>();

  const fetchCorporationData = React.useCallback(() => {
    getCorporation(company.corporationId).then((data: CorporationDataForm) => {
      setCorporation(data);
    });
  }, [company.corporationId]);

  React.useEffect(() => {
    fetchCorporationData();
  }, [fetchCorporationData]);

  const cleanErrors = (): void => {
    if (
      formErrorsDispatch &&
      formErrors != null &&
      Object.keys(formErrors).length > 0
    ) {
      formErrorsDispatch({
        type: "Remove",
        inputName: "xmlBuilderNumbers",
      });
      formErrorsDispatch({
        type: "Remove",
        inputName: "xmlCorporateBuilderNumber",
      });
      if (formState?.price === undefined) {
        formErrorsDispatch({
          type: "Remove",
          inputName: "price",
        });
      }
    }
  };

  return (
    <AnimatedContainer>
      <FormStyled>
        <Grid container spacing={2} sx={{ px: 7 }}>
          <Grid item xs={12}>
            <Select
              label="Service"
              options={servicesOptions}
              name="serviceCode"
              onChange={cleanErrors}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Plan Type"
              name="feeFrequency"
              options={frequencyOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberInput label="Price" name="price" required />
          </Grid>
          {formState?.serviceCode === CompanyServiceCode.XMLImport && (
            <XMLImportFormFields
              xmlBuilderNumbers={company.xmlBuilderNumbers}
              xmlCorporateBuilderNumber={corporation?.xmlCorporateBuilderNumber}
            />
          )}
        </Grid>
      </FormStyled>
    </AnimatedContainer>
  );
};
