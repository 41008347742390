import React from "react";

import { Delete } from "@mui/icons-material";
import { Column } from "react-table";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import {
  Format,
  PersistentRightModal,
  RoleEmployeeLabel,
} from "@homesusa/core";
import {
  GridTable,
  GridToolbar,
  GridProvider,
  FetchData,
} from "@homesusa/grid-table";
import { Employee } from "core/interfaces";
import { IconButtonStyled } from "core/styles";
import { AnimatedContainer } from "core/components";
import { CompanyContext } from "modules/company/contexts";
import { useDeleteEmployees } from "modules/company/hooks";
import { NewEmployeeForm } from "./new-employee-form.component";
import { EmployeeDetails } from "./employee-details.component";
import { FormContext, FormMode } from "@homesusa/form";

export function EmployeesGrid(): JSX.Element {
  const { employees, getEmployees } = React.useContext(CompanyContext);
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { formMode } = React.useContext(FormContext);

  const [selectedEmployees, setSelectedEmployees] = React.useState<string[]>(
    []
  );
  const [employeeId, setEmployeeId] = React.useState<string>();

  const handleCloseDeleteModal = (): void => setShowDeleteModal(false);
  const handleShowDeleteModal = (employeeId: string): void => {
    setShowDeleteModal(true);
    const employeeList: string[] = [];
    employeeList.push(employeeId);
    setSelectedEmployees(employeeList);
  };

  const handleShowEditModal = (employeeId: string): void => {
    setShowEditModal(true);
    setEmployeeId(employeeId);
  };

  const onDelete = useDeleteEmployees();
  const deleteEmployee = (): void => {
    handleCloseDeleteModal();
    onDelete(selectedEmployees);
  };

  const getEmployee = ({ row }: { row: Employee }): void => {
    handleShowEditModal(row.id);
  };

  const columns: Array<Column<Employee>> = React.useMemo(
    () => [
      {
        Header: "Row Number",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "User Name",
        accessor: (data: Employee): string => data.userName,
      },
      {
        Header: "First Name",
        accessor: (data: Employee): string => data.firstName,
      },
      {
        Header: "Last Name",
        accessor: (data: Employee): string => data.lastName,
      },
      {
        Header: "Email",
        accessor: (data: Employee): string => data.email,
      },
      {
        Header: "Title",
        accessor: (data: Employee): string => data.title,
      },
      {
        Header: "Created",
        accessor: (data: Employee): string =>
          Format.DateTime(data.sysCreatedOn),
        id: "sysCreatedOn",
      },
      {
        Header: "Role",
        accessor: (data: Employee): string =>
          RoleEmployeeLabel.get(data.roleName) ?? data.roleName,
      },
    ],
    []
  );

  if (formMode !== FormMode.ReadOnly) {
    columns.push({
      Header: "Actions",
      disableSortBy: true,
      accessor: (data: Employee): string => data.id,
      Cell: function Actions({ value }: { value: string }): JSX.Element {
        return (
          <div>
            <IconButtonStyled
              title="Delete"
              onClick={React.useCallback(
                (
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ): void => {
                  event.stopPropagation();
                  handleShowDeleteModal(value);
                },
                [value]
              )}
            >
              <Delete />
            </IconButtonStyled>
          </div>
        );
      },
    });
  }

  const fetchData = React.useCallback(
    async (props: FetchData): Promise<void> => {
      await getEmployees(props);
    },
    [getEmployees]
  );

  const fetchDataForDownloading = React.useCallback(async (): Promise<
    Employee[]
  > => {
    const result = await getEmployees({ isForDownloading: true });
    return result.data;
  }, [getEmployees]);

  if (!employees) {
    return <p>Loading...</p>;
  }

  return (
    <AnimatedContainer>
      <GridProvider
        options={{ columns, data: employees.data }}
        totalRows={employees?.total}
        fetchData={fetchData}
      >
        <GridToolbar>
          {formMode !== FormMode.ReadOnly && (
            <GridToolbar.AddButton
              onClick={(): void => setShowAddModal(true)}
            />
          )}
          <GridToolbar.ExportButtons
            onClick={fetchDataForDownloading}
            fileName="employees"
          />
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <GridTable onRowClick={getEmployee} />
      </GridProvider>
      <PersistentRightModal
        title="New Employee"
        open={showAddModal}
        onChange={(value): void => setShowAddModal(value)}
      >
        <NewEmployeeForm />
      </PersistentRightModal>

      {employeeId && (
        <PersistentRightModal
          title="Edit Employee"
          open={showEditModal}
          onChange={(value): void => setShowEditModal(value)}
        >
          <EmployeeDetails employeeId={employeeId} />
        </PersistentRightModal>
      )}

      <Dialog open={showDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>
          <p> Are you sure you want to delete this employee? </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteEmployee}>Delete</Button>
        </DialogActions>
      </Dialog>
    </AnimatedContainer>
  );
}
