import React from "react";

import { GridProvider, GridTable } from "@homesusa/grid-table";
import { FormContext, FormMode } from "@homesusa/form";
import { SectionBox } from "core/components";
import { useContactEmailsColumns } from "modules/company/hooks";
import {
  CompanyFormContextProps,
  GridElementContext,
} from "modules/company/contexts";

export const ContactEmailsGrid = (): JSX.Element => {
  const {
    formState: { emailLeads },
    formMode,
  } = React.useContext<CompanyFormContextProps>(FormContext);
  const { setEdit } = React.useContext(GridElementContext);
  const columns = useContactEmailsColumns(formMode === FormMode.ReadOnly);

  return (
    <SectionBox>
      <GridProvider
        options={{
          columns,
          data: emailLeads,
          manualPagination: false,
        }}
        totalRows={emailLeads?.length}
        pageSize={5}
      >
        <GridTable onRowClick={setEdit} />
      </GridProvider>
    </SectionBox>
  );
};
