import React from "react";
import { Grid, Typography } from "@mui/material";

import { FormContext } from "@homesusa/form";
import { useRefresh } from "@homesusa/core";
import { EmailLeads } from "core/interfaces";
import { ContactEmailForm } from "./contact-emails-form.component";
import { ContactEmailsGrid } from "./contact-emails-grid.component";
import {
  GridElementContext,
  CompanyFormContextProps,
} from "modules/company/contexts";

export const ContactEmailsSection = (): JSX.Element => {
  const {
    formState: { emailLeads },
    formDispatch,
  } = React.useContext<CompanyFormContextProps>(FormContext);

  const [hideForm, refreshForm] = useRefresh();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState<number>();
  const [data, setData] = React.useState<EmailLeads>();

  const setEdit = ({ index }: { index: number }): void => {
    setData(emailLeads[index]);
    setEditIndex(index);
    setIsEdit(true);
    refreshForm();
  };

  const onSuccess = (): void => {
    setData(undefined);
    setEditIndex(undefined);
    setIsEdit(false);
    refreshForm();
  };

  const onSave = (state: EmailLeads): void => {
    if (!formDispatch) {
      return;
    }
    if (isEdit && editIndex != undefined) {
      formDispatch({
        type: "UpdateEmailLead",
        payload: state,
        index: editIndex,
      });
    } else {
      formDispatch({
        type: "AddEmailLead",
        payload: state,
      });
    }
  };

  const onDelete = (index: number): void => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "RemoveEmailLead",
      index,
    });
    if (editIndex === index) {
      onSuccess();
    }
  };

  return (
    <GridElementContext.Provider
      value={{
        isEdit,
        onDelete,
        setEdit,
      }}
    >
      <Typography sx={{ width: "100%", mb: 2 }} variant="h2">
        Centralized Company Lead Emails
      </Typography>
      <Grid container columnSpacing={5}>
        <Grid item xs={5}>
          {!hideForm && (
            <ContactEmailForm
              initialData={data}
              onSave={onSave}
              onSuccess={onSuccess}
            />
          )}
        </Grid>
        <Grid item xs={7}>
          <ContactEmailsGrid />
        </Grid>
      </Grid>
    </GridElementContext.Provider>
  );
};
