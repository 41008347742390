import React from "react";
import { LinearProgress } from "@mui/material";

import { FetchData, GridResponse } from "@homesusa/grid-table";
import { RoleEmployee } from "@homesusa/core";
import {
  CompanyDataForm,
  ContextFetchData,
  Employee,
  Service,
} from "core/interfaces";
import { getCompany } from "core/services/companies/company.service";
import { getActiveServices } from "core/services/companies/company-service.service";
import { getCompanyEmployees } from "core/services/companies/company-employee.service";
import { CompanyContext } from "../contexts";
import { ServicesFetchData } from "../interfaces";
import { getCompanySavedChanges } from "core/services/companies/company-saved-changes.service";
import { CompanySavedChanges } from "core/interfaces/company-saved-changes.interface";

export function CompanyProvider({
  companyId,
  children,
}: {
  children: React.ReactNode;
  companyId: string;
}): JSX.Element {
  const [company, setCompany] = React.useState<CompanyDataForm>();
  const [services, setServices] = React.useState<GridResponse<Service>>();
  const [employees, setEmployees] = React.useState<GridResponse<Employee>>();
  const [savedChanges, setSavedChanges] =
    React.useState<GridResponse<CompanySavedChanges>>();
  const [photoManagerEmployees, setPhotoManagerEmployees] =
    React.useState<Employee[]>();

  const fetchCompanyData = React.useCallback(() => {
    getCompany(companyId).then((data: CompanyDataForm) => {
      setCompany(data);
    });
  }, [companyId]);

  const getServices = React.useCallback(
    async ({
      pageSize = 50,
      pageIndex = 0,
      sortBy,
      globalFilter,
      serviceCode,
      isForDownloading,
    }: ServicesFetchData): Promise<GridResponse<Service>> => {
      const response = await getActiveServices(companyId, {
        serviceCode: serviceCode,
        top: pageSize,
        skip: pageIndex,
        searchBy: globalFilter,
        sortBy: sortBy ?? "+serviceCode",
        isForDownloading,
      });
      setServices(response);
      return response;
    },
    [companyId]
  );

  const getHistoryChanges = React.useCallback(
    async ({
      pageSize = 50,
      pageIndex = 0,
      sortBy,
      globalFilter,
    }: FetchData): Promise<GridResponse<CompanySavedChanges>> => {
      const response = await getCompanySavedChanges({
        skip: pageIndex,
        searchBy: globalFilter,
        companyId: companyId,
        sortBy,
        top: pageSize,
      });
      setSavedChanges(response);
      return response;
    },
    [companyId]
  );

  const getEmployees = React.useCallback(
    async ({
      pageSize = 50,
      pageIndex = 0,
      sortBy = "+firstName,+lastName",
      globalFilter,
      isForDownloading,
    }: ContextFetchData): Promise<GridResponse<Employee>> => {
      const employees = await getCompanyEmployees(companyId, {
        top: pageSize,
        skip: pageIndex,
        searchBy: globalFilter,
        sortBy,
        isForDownloading,
      });
      setEmployees(employees);
      return employees;
    },
    [companyId]
  );

  const getPhotoManagerEmployees =
    React.useCallback(async (): Promise<void> => {
      const employees = await getCompanyEmployees(companyId, {
        roles: [RoleEmployee.CompanyAdmin, RoleEmployee.CompanyAdminReadonly],
      });
      setPhotoManagerEmployees(employees.data);
    }, [companyId]);

  React.useEffect(() => {
    fetchCompanyData();
    getEmployees({});
    getHistoryChanges({
      pageSize: 50,
      pageIndex: 0,
    });
  }, [fetchCompanyData, getEmployees, getHistoryChanges]);

  if (!company) {
    return <LinearProgress />;
  }

  return (
    <CompanyContext.Provider
      value={{
        company,
        getCompany: fetchCompanyData,
        services,
        getServices,
        employees,
        getEmployees,
        savedChanges,
        getHistoryChanges,
        photoManagerEmployees,
        getPhotoManagerEmployees,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}
