export enum MlsPhoneTypeForm {
  alternative = "alternative",
  appointment = "appointment",
}

export const MlsPhoneTypeFormLabel = new Map<MlsPhoneTypeForm, string>([
  [MlsPhoneTypeForm.alternative, "Alternate Phone"],
  [MlsPhoneTypeForm.appointment, "Appointment Phone"],
]);

export const MlsPhoneTypeFormTooltip = new Map<MlsPhoneTypeForm, string>([
  [
    MlsPhoneTypeForm.alternative,
    "Alternate Phone entered here will populate into the Showing Tab when new communities and listings are created.d",
  ],
  [
    MlsPhoneTypeForm.appointment,
    "Appointment Phone entered here will populate into the Showing Tab when new communities and listings are created.",
  ],
]);
