import React from "react";
import { FormTabProps, FormTabsOutlet } from "@homesusa/form";
import { useNotesMenuButton } from "@homesusa/notes";
import { FormCompaniesTabs } from "../form-update/tabs/tab.component";
import { MlsComponent } from "../form-update/msl/mls.component";
import { PhotoServices } from "../form-update/photo-services.component";
import { CompanyContext } from "modules/company/contexts";
import { useHasRole } from "@homesusa/auth";
import { CompanyFormTab, CompanyFormTabLabel } from "modules/company/enums";

type formTabsType = Record<string, FormTabProps>;

export const FormUpdateComponent = (): JSX.Element => {
  const { company } = React.useContext(CompanyContext);
  const { isMlsAdministrator } = useHasRole();
  const notesMenuButton = useNotesMenuButton();

  const getTabProps = (tab: CompanyFormTab): { id: string; label: string } => ({
    id: tab,
    label: CompanyFormTabLabel.get(tab) ?? tab,
  });

  const tabs = React.useMemo(() => {
    const formTabs: formTabsType = {
      [CompanyFormTab.General]: {
        ...getTabProps(CompanyFormTab.General),
        node: <FormCompaniesTabs />,
      },
      [CompanyFormTab.Mls]: {
        ...getTabProps(CompanyFormTab.Mls),
        node: <MlsComponent />,
      },
      [CompanyFormTab.PhotographyServices]: {
        ...getTabProps(CompanyFormTab.PhotographyServices),
        node: <PhotoServices readOnly={!isMlsAdministrator} />,
      },
    };
    return formTabs;
  }, [company.id]);

  const menu = React.useMemo(() => [notesMenuButton], [notesMenuButton]);

  return <FormTabsOutlet tabs={tabs} menuOptions={menu} />;
};
