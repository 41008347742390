import React, { Fragment } from "react";
import { Save as SaveIcon } from "@mui/icons-material";

import { useHasRole } from "@homesusa/auth";
import { RoleEmployee } from "@homesusa/core";
import { SubmitButton } from "@homesusa/layout";
import { InfoIconButton } from "core/components";
import { useUpdateCompany } from "../../hooks";
import { CompanyContext } from "../../contexts";
import { DeleteCompanyButton } from "./delete-company-button.component";

export function CompanyActionsGroup(): JSX.Element {
  const { hasEmployeeRole, isMlsAdministrator } = useHasRole();
  const {
    company: { sysCreatedOn, createdBy, sysModifiedOn, modifiedBy },
  } = React.useContext(CompanyContext);
  const onSave = useUpdateCompany();

  return (
    <Fragment>
      {(isMlsAdministrator || hasEmployeeRole([RoleEmployee.CompanyAdmin])) && (
        <SubmitButton
          onClick={onSave}
          buttonProps={{
            variant: "contained",
            color: "primary",
            startIcon: <SaveIcon />,
          }}
        >
          Save
        </SubmitButton>
      )}
      {isMlsAdministrator && <DeleteCompanyButton />}
      <InfoIconButton
        information={{
          sysCreatedOn,
          sysModifiedOn,
          createdBy,
          modifiedBy,
        }}
      />
    </Fragment>
  );
}
