import React from "react";

import { GridProvider, GridTable } from "@homesusa/grid-table";
import { SectionBox } from "core/components";

import { MarketProspectingCredentials } from "core/interfaces";
import { GridElementContext } from "modules/company/contexts";
import { useReverseProspectColumns } from "../hooks";

export const ReverseProspectsGrid = ({
  markets,
}: {
  markets: MarketProspectingCredentials[];
}): JSX.Element => {
  const columns = useReverseProspectColumns();
  const { setEdit } = React.useContext(GridElementContext);
  return (
    <SectionBox>
      <GridProvider
        options={{
          columns,
          data: markets,
          manualPagination: false,
        }}
        totalRows={markets?.length}
        pageSize={5}
      >
        <GridTable onRowClick={setEdit} />
      </GridProvider>
    </SectionBox>
  );
};
