import React from "react";
import { useNavigate } from "react-router-dom";

import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { deleteCompany } from "core/services/companies/company.service";
import { CompanyContext } from "../contexts";

export const useDeleteCompany = (): (() => void) => {
  const navigate = useNavigate();
  const { company } = React.useContext(CompanyContext);
  const { addAlert } = React.useContext(AppContext);

  const onDelete = (): void => {
    if (!isValidGuid(company.id)) {
      return;
    }

    deleteCompany(company.id).then(() => {
      addAlert({
        message: "The company was successfully deleted",
        variant: "success",
      });
      navigate(`/`);
    });
  };

  return onDelete;
};
