import React from "react";
import { Button, Box, Stack } from "@mui/material";
import { FormContext, FormMode } from "@homesusa/form";

export function DisplayImage({
  handleChange,
  label,
  defaultImage,
}: {
  handleChange: (value: File) => void;
  label?: string;
  defaultImage?: string;
}): JSX.Element {
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [preview, setPreview] = React.useState<string | undefined>(
    defaultImage
  );
  const { formMode } = React.useContext(FormContext);

  const handleSelectedFile = (file: File | undefined): void => {
    setSelectedFile(file);
  };

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      return;
    }

    handleChange(selectedFile);
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return (): void => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Box sx={{ width: "100%" }}>
        {formMode !== FormMode.ReadOnly && (
          <Button component="label" variant="outlined" sx={{ width: "100%" }}>
            {label ?? "Load image"}
            <input
              type="file"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                if (!event.target.files || event.target.files.length === 0) {
                  return handleSelectedFile(undefined);
                }

                // I've kept this example simple by using the first image instead of multiple
                handleSelectedFile(event.target.files[0]);
              }}
              hidden
            />
          </Button>
        )}
        {preview && (
          <Box sx={{ textAlign: "center", position: "relative", mt: 1 }}>
            <Box sx={{ overflow: "hidden", width: "100%", height: 160 }}>
              <Box
                component="img"
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={preview}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
