import React from "react";

import { FetchData, GridResponse } from "@homesusa/grid-table";
import {
  CompanyDataForm,
  ContextFetchData,
  Employee,
  Service,
} from "core/interfaces";
import { ServicesFetchData } from "../interfaces";
import { CompanySavedChanges } from "core/interfaces/company-saved-changes.interface";

export const CompanyContext = React.createContext<{
  company: CompanyDataForm;
  getCompany: () => void;
  photoManagerEmployees?: Employee[];
  getPhotoManagerEmployees: () => Promise<void>;
  savedChanges?: GridResponse<CompanySavedChanges>;
  getHistoryChanges: (
    params: FetchData
  ) => Promise<GridResponse<CompanySavedChanges>>;
  employees?: GridResponse<Employee>;
  getEmployees: (params: ContextFetchData) => Promise<GridResponse<Employee>>;
  services?: GridResponse<Service>;
  getServices: (params: ServicesFetchData) => Promise<GridResponse<Service>>;
}>(Object.assign({}));
