import React from "react";

import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { updateCorporation } from "core/services/corporations/corporation.service";
import { CorporationContext } from "../contexts";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { CorporationFormContextProps } from "../contexts/corporation-form";

export const useUpdateCorporation = (): (() => void) => {
  const { getCorporation } = React.useContext(CorporationContext);
  const { formState } =
    React.useContext<CorporationFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);

  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    if (!isValidGuid(formState.id)) {
      return Promise.resolve();
    }

    updateCorporation(formState).then(() => {
      addAlert({
        message: "The request was successfully updated",
        variant: "success",
      });
      getCorporation();
    });
    return Promise.resolve();
  };

  return onSave;
};
