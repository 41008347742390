import React, { Fragment } from "react";

import { tableContainerStyle } from "@homesusa/core";
import { GridTable, GridProvider, Columns } from "@homesusa/grid-table";
import { AccordionBox, SummaryField, RowsViewer } from "@homesusa/record";
import { CompanySavedChangesContext } from "modules/company-history/contexts";

interface BuilderNumbers {
  number: string;
}
type BuilderNumberSummaryField = SummaryField<BuilderNumbers>;

function BuilderNumberGrid({
  phones,
}: {
  phones: BuilderNumberSummaryField[];
}): JSX.Element {
  const columns = React.useMemo(() => {
    const columns: Columns<BuilderNumberSummaryField> = [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "Number",
        accessor: (data: BuilderNumberSummaryField): JSX.Element => (
          <RowsViewer
            item={data}
            displayValue={(item: BuilderNumbers): string => item.number}
          />
        ),
      },
    ];
    return columns;
  }, []);

  return (
    <GridProvider
      pageSize={10}
      options={{ columns, data: phones, manualPagination: false }}
    >
      <GridTable />
    </GridProvider>
  );
}

export function BuilderNumberSummary(): JSX.Element {
  const { summary } = React.useContext(CompanySavedChangesContext);
  const phones: BuilderNumberSummaryField[] = React.useMemo(() => {
    const section = summary.find((item) => item?.name === "BuilderNumbers");
    return section ? Object.assign(section).fields : [];
  }, [summary]);

  if (phones.length === 0) {
    return <Fragment></Fragment>;
  }

  return (
    <AccordionBox title="BuilderNumbers" sx={tableContainerStyle}>
      <BuilderNumberGrid phones={phones} />
    </AccordionBox>
  );
}
