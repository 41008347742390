import React from "react";
import { Grid, Typography } from "@mui/material";

import { FormContext } from "@homesusa/form";
import { useRefresh } from "@homesusa/core";
import {
  GridElementContext,
  CompanyFormContextProps,
} from "modules/company/contexts";
import { Phones } from "core/interfaces";
import { PhoneTypeForm } from "core/enums";
import { ContactPhoneForm } from "../../../contact-phone-form.component";
import { ContactPhonesGrid } from "../../../contact-phone-grid.component";
import { useLocalOptions } from "modules/company/hooks";

export function ContactPhoneSection(): JSX.Element {
  const {
    formState: { phones },
    formDispatch,
  } = React.useContext<CompanyFormContextProps>(FormContext);

  const [hideForm, refreshForm] = useRefresh();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState<number>();
  const [data, setData] = React.useState<Phones>();
  const { phoneType } = useLocalOptions();
  const typesToShow = Object.values(PhoneTypeForm);
  const phonesFiltered = React.useMemo(() => {
    return phones.filter((phone) =>
      typesToShow.includes(phone.phoneType as PhoneTypeForm)
    );
  }, [phones]);

  const getIndex = (phone: Phones): number => {
    return phones.findIndex(
      ({ number, phoneType }) =>
        number === phone.number && phoneType === phone.phoneType
    );
  };
  const setEdit = ({ index }: { index: number }): void => {
    setData(phonesFiltered[index]);
    setEditIndex(getIndex(phonesFiltered[index]));
    setIsEdit(true);
    refreshForm();
  };

  const onSuccess = (): void => {
    setData(undefined);
    setEditIndex(undefined);
    setIsEdit(false);
    refreshForm();
  };

  const onSave = (phoneState: Phones): void => {
    if (!formDispatch) {
      return;
    }
    if (isEdit && editIndex != undefined) {
      formDispatch({
        type: "UpdatePhone",
        payload: phoneState,
        index: editIndex,
      });
    } else {
      formDispatch({
        type: "AddPhone",
        payload: phoneState,
      });
    }
  };

  const onDelete = (index: number): void => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "RemovePhone",
      index: getIndex(phonesFiltered[index]),
    });
    if (editIndex === index) {
      onSuccess();
    }
  };

  return (
    <GridElementContext.Provider
      value={{
        isEdit,
        setEdit,
        onDelete,
      }}
    >
      <Typography sx={{ width: "100%", mb: 3 }} variant="h2">
        Company Phones
      </Typography>
      <Grid container columnSpacing={5}>
        <Grid item xs={5}>
          {!hideForm && (
            <ContactPhoneForm
              initialData={data}
              onSave={onSave}
              onSuccess={onSuccess}
              typePhones={phoneType}
            />
          )}
        </Grid>
        <Grid item xs={7}>
          <ContactPhonesGrid phones={phonesFiltered} gridType="general" />
        </Grid>
      </Grid>
    </GridElementContext.Provider>
  );
}
