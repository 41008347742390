import React, { Fragment } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Delete, Save as SaveIcon } from "@mui/icons-material";

import { isValidGuid } from "@homesusa/core";
import { FormToolbar } from "@homesusa/form";
import { useDocumentTitle } from "@homesusa/layout";
import { InfoIconButton } from "core/components";
import { useUpdateCorporation, useDeleteCorporation } from "../hooks";
import { CorporationContext } from "../contexts";
import { SysInfo } from "core/interfaces/sys-info";

function CorporationToolbar(): JSX.Element {
  const { corporation } = React.useContext(CorporationContext);
  useDocumentTitle(`Corporation - ${corporation?.name}`);

  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const handleShowModal = (): void => setShowModal(true);

  const onSave = useUpdateCorporation();
  const onDelete = useDeleteCorporation();

  const isValidCorporationId = React.useMemo((): boolean => {
    return !!corporation && isValidGuid(corporation.id);
  }, [corporation]);

  const deleteCorporation = (): void => {
    handleCloseModal();
    onDelete();
  };

  const sysInfo = React.useMemo((): SysInfo => {
    const { sysCreatedOn, sysModifiedOn, createdBy, modifiedBy } = corporation;
    return { sysCreatedOn, sysModifiedOn, createdBy, modifiedBy };
  }, [corporation]);

  return (
    <Fragment>
      <FormToolbar>
        <FormToolbar.Left title={corporation?.name} />
        <FormToolbar.Right>
          <Button
            variant="contained"
            color="primary"
            onClick={(): void => onSave()}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <IconButton
            onClick={handleShowModal}
            disabled={!isValidCorporationId}
            title="Delete"
          >
            <Delete />
          </IconButton>
          <InfoIconButton information={sysInfo} />
        </FormToolbar.Right>
      </FormToolbar>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Delete Corporation</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Corporation?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteCorporation}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default CorporationToolbar;
