import React from "react";
import { Column } from "react-table";
import { Company } from "../interfaces";
import { Format, MarketCode, MarketCodeLabel } from "@homesusa/core";
import { CompanyTypeLabel } from "../enums";

type companyColumn = Array<Column<Company>>;

export const useCompanyGridColumns = (): companyColumn => {
  const columns: companyColumn = React.useMemo(
    () => [
      {
        Header: "Row Number",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "Name",
        accessor: (data: Company): string => data.name,
      },
      {
        Header: "Type",
        accessor: (data: Company): string =>
          CompanyTypeLabel.get(data.type) ?? data.type,
      },
      {
        Header: "Address",
        accessor: (data: Company): string => data.address,
      },
      {
        Header: "City",
        accessor: (data: Company): string => data.city,
      },
      {
        Header: "Market",
        accessor: (data: Company): string =>
          MarketCodeLabel.get(data.market as MarketCode) ?? data.market,
      },
      {
        Header: "Web Url",
        accessor: (data: Company): string => data.webUrl,
      },
      {
        Header: "Created",
        accessor: (data: Company): string => Format.DateTime(data.sysCreatedOn),
        id: "sysCreatedOn",
      },
    ],
    []
  );

  return columns;
};
