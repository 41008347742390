import React from "react";

import { AppContext } from "@homesusa/layout";
import { deleteService } from "core/services/companies/company-service.service";
import { CompanyContext, ServiceFormContext } from "../contexts";
import { FormContext } from "@homesusa/form";

// eslint-disable-next-line
export const useDeleteService = () => {
  const { company, getServices } = React.useContext(CompanyContext);
  const { formState } = React.useContext<ServiceFormContext>(FormContext);
  const { addAlert } = React.useContext(AppContext);

  const onDeleteById = (serviceId: string): Promise<void> =>
    deleteService(serviceId, company.id).then(() => {
      addAlert({
        message: "The service was successfully deleted",
        variant: "success",
      });
      getServices({});

      return Promise.resolve();
    });

  const onDelete = (): Promise<void> => onDeleteById(formState.id);

  return { onDelete, onDeleteById };
};
