import React from "react";

import { GridResponse } from "@homesusa/grid-table";
import {
  Company,
  ContextFetchData,
  CorporationDataForm,
} from "core/interfaces";
import {
  getCorporation,
  getCorporationCompanies,
} from "core/services/corporations/corporation.service";
import { CorporationContext } from "../contexts";

export function CorporationProvider({
  corporationId: corporationId,
  children,
}: {
  children: React.ReactNode;
  corporationId: string;
}): JSX.Element {
  const defaultSortBy = "+name";
  const [corporation, setCorporation] = React.useState<CorporationDataForm>();
  const [companies, setCompanies] = React.useState<GridResponse<Company>>({
    data: [],
    total: 0,
  });

  const fetchCorporationData = React.useCallback(() => {
    getCorporation(corporationId).then((data: CorporationDataForm) => {
      setCorporation(data);
    });
  }, [corporationId]);

  const getCompanies = React.useCallback(
    ({
      pageSize = 50,
      pageIndex = 0,
      sortBy,
      globalFilter,
    }: ContextFetchData): Promise<void> => {
      const filters = {
        skip: pageIndex * pageSize,
        top: pageSize,
        searchBy: globalFilter,
        sortBy: sortBy ?? defaultSortBy,
      };

      return getCorporationCompanies(corporationId, filters).then((data) => {
        setCompanies(data);
      });
    },
    [corporationId]
  );

  React.useEffect(() => {
    fetchCorporationData();
  }, [fetchCorporationData]);

  if (!corporation) {
    return <p data-testid="loading">Loading...</p>;
  }

  return (
    <CorporationContext.Provider
      value={{
        corporation: corporation,
        getCorporation: fetchCorporationData,
        companies: companies,
        getCompanies,
      }}
    >
      {children}
    </CorporationContext.Provider>
  );
}
