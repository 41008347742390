import React from "react";
import { Column } from "react-table";
import { Format } from "@homesusa/core";
import {
  GridToolbar,
  GridProvider,
  FetchData,
  GridTable,
} from "@homesusa/grid-table";
import { AnimatedContainer } from "core/components";
import { CompanyContext } from "modules/company/contexts";
import { CompanySavedChanges } from "core/interfaces/company-saved-changes.interface";
import { Box } from "@mui/material";
import { tableContainerStyle } from "./saved-changes.style";
import { useNavigate, useParams } from "react-router-dom";
import { RouterPath } from "modules/company/enums";

export function SavedChangesGrid(): JSX.Element {
  const { savedChanges, getHistoryChanges } = React.useContext(CompanyContext);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const columns: Array<Column<CompanySavedChanges>> = React.useMemo(
    () => [
      {
        Header: "Saved By",
        accessor: (data: CompanySavedChanges): string => data.createdBy,
        id: "sysCreatedBy",
      },
      {
        Header: "Saved On",
        accessor: (data: CompanySavedChanges): string =>
          Format.DateTime(data.sysCreatedOn),
        id: "sysCreatedOn",
      },
    ],
    []
  );
  const fetchData = React.useCallback(
    async (props: FetchData): Promise<void> => {
      await getHistoryChanges(props);
    },
    [getHistoryChanges]
  );
  const onRowClick = ({ row }: { row: CompanySavedChanges }): void => {
    const companyId = params && params.id ? params.id : "";
    let path = RouterPath.CompanyHistoryDetails.replace(":id", companyId);
    path = path.replace(":historyId", row.id);
    navigate(path);
  };
  if (!savedChanges) {
    return <p>Loading...</p>;
  }

  return (
    <AnimatedContainer>
      <GridProvider
        options={{ columns, data: savedChanges.data }}
        totalRows={savedChanges?.total}
        fetchData={fetchData}
      >
        <GridToolbar>
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <Box mt={2} />
        <GridTable sx={tableContainerStyle} onRowClick={onRowClick} />
      </GridProvider>
    </AnimatedContainer>
  );
}
