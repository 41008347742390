import { CompanyDataForm as Company } from "core/interfaces";
import { PhoneActions } from "./phone-actions.type";

export const companyFormPhonesReducer = (
  state: Company,
  action: PhoneActions
): Company => {
  switch (action.type) {
    case "AddPhone": {
      return {
        ...state,
        phones: [...state.phones, action.payload],
      };
    }
    case "RemovePhone": {
      if (state.phones.length < action.index) {
        return state;
      }
      state.phones.splice(action.index, 1);
      return {
        ...state,
        phones: [...state.phones],
      };
    }
    case "UpdatePhone": {
      if (state.phones.length < action.index) {
        return state;
      }
      state.phones[action.index] = action.payload;
      return {
        ...state,
        phones: [...state.phones],
      };
    }
    default: {
      return state;
    }
  }
};
