import React from "react";
import { useNavigate } from "react-router-dom";

import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { deleteCorporation } from "core/services/corporations/corporation.service";
import { CorporationContext } from "../contexts";

export const useDeleteCorporation = (): (() => void) => {
  const navigate = useNavigate();
  const { corporation } = React.useContext(CorporationContext);
  const { addAlert } = React.useContext(AppContext);

  const onDelete = (): void => {
    if (!isValidGuid(corporation.id)) {
      return;
    }

    deleteCorporation(corporation.id).then(() => {
      addAlert({
        message: "The corporation was successfully deleted",
        variant: "success",
      });
      navigate(`/corporations`);
    });
  };

  return onDelete;
};
