import { CorporationDataForm as Corporation } from "core/interfaces";
import { ReverseProspectActions } from "./reverse-prospect-actions.type";

export const CorporationReverseProspectFormReducer = (
  state: Corporation,
  action: ReverseProspectActions
): Corporation => {
  switch (action.type) {
    case "AddReverseProspect": {
      return {
        ...state,
        marketProspectingCredentials: [
          ...state.marketProspectingCredentials,
          action.payload,
        ],
      };
    }
    case "RemoveReverseProspect": {
      if (state.marketProspectingCredentials.length < action.index) {
        return state;
      }
      state.marketProspectingCredentials.splice(action.index, 1);
      return {
        ...state,
        marketProspectingCredentials: [...state.marketProspectingCredentials],
      };
    }
    case "UpdateReverseProspect": {
      if (state.marketProspectingCredentials.length < action.index) {
        return state;
      }
      state.marketProspectingCredentials[action.index] = action.payload;
      return {
        ...state,
        marketProspectingCredentials: [...state.marketProspectingCredentials],
      };
    }
    default: {
      return state;
    }
  }
};
