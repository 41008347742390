import {
  MarketCode,
  MarketCodeLabel,
  CompanyServiceCode,
  CompanyServiceCodeLabel,
} from "@homesusa/core";
import {
  CompanyTypeForm,
  StateType,
  ResizeTypes,
  ProtocolType,
  CompanyTypeFormLabel,
  StateTypeLabel,
  ResizeTypeLabel,
  ProtocolTypeLabel,
  RemarkType,
  RemarkTypeLabel,
  CompanySectionForm,
  CompanySectionFormLabel,
  FeeFrequency,
  FeeFrequencyLabel,
  EmailEntityTypeForm,
  EmailPriorityForm,
  PhoneTypeForm,
  EmailEntityTypeFormLabel,
  EmailPriorityFormLabel,
  PhoneTypeFormLabel,
  MlsPhoneTypeForm,
  MlsPhoneTypeFormLabel,
} from "core/enums";
interface localOptions {
  stateOptions: Record<string, never>;
  resizeOptions: Record<string, never>;
  protocolOptions: Record<string, never>;
  marketOptions: Record<string, never>;
  companyOptions: Record<string, never>;
  remarkOptions: Record<string, never>;
  sectionsOptions: Record<string, never>;
  servicesOptions: Record<string, never>;
  frequencyOptions: Record<string, never>;
  emailTypeOptions: Record<string, never>;
  emailPriorityOptions: Record<string, never>;
  phoneType: Record<string, never>;
  mlsPhoneType: Record<string, never>;
}

type optionsType =
  | typeof CompanyTypeForm
  | typeof StateType
  | typeof ProtocolType
  | typeof ResizeTypes
  | typeof MarketCode
  | typeof RemarkType
  | typeof CompanySectionForm
  | typeof CompanyServiceCode
  | typeof FeeFrequency
  | typeof EmailEntityTypeForm
  | typeof EmailPriorityForm
  | typeof PhoneTypeForm
  | typeof MlsPhoneTypeForm;

type optionsLabelType =
  | typeof CompanyTypeFormLabel
  | typeof StateTypeLabel
  | typeof ResizeTypeLabel
  | typeof ProtocolTypeLabel
  | typeof MarketCodeLabel
  | typeof RemarkTypeLabel
  | typeof CompanySectionFormLabel
  | typeof CompanyServiceCodeLabel
  | typeof FeeFrequencyLabel
  | typeof EmailEntityTypeFormLabel
  | typeof EmailPriorityFormLabel
  | typeof PhoneTypeFormLabel
  | typeof MlsPhoneTypeFormLabel;

export const useLocalOptions = (): localOptions => {
  const getOptions = (
    options: optionsType,
    label: optionsLabelType
  ): Record<string, never> => {
    return Object.values(options).reduce(
      (a, x) => ({ ...a, [x]: label.get(x as keyof optionsType) }),
      {}
    );
  };

  const options: localOptions = {
    stateOptions: getOptions(StateType, StateTypeLabel),
    resizeOptions: getOptions(ResizeTypes, ResizeTypeLabel),
    protocolOptions: getOptions(ProtocolType, ProtocolTypeLabel),
    marketOptions: getOptions(MarketCode, MarketCodeLabel),
    companyOptions: getOptions(CompanyTypeForm, CompanyTypeFormLabel),
    remarkOptions: getOptions(RemarkType, RemarkTypeLabel),
    sectionsOptions: getOptions(CompanySectionForm, CompanySectionFormLabel),
    servicesOptions: getOptions(CompanyServiceCode, CompanyServiceCodeLabel),
    frequencyOptions: getOptions(FeeFrequency, FeeFrequencyLabel),
    emailTypeOptions: getOptions(EmailEntityTypeForm, EmailEntityTypeFormLabel),
    emailPriorityOptions: getOptions(EmailPriorityForm, EmailPriorityFormLabel),
    phoneType: getOptions(PhoneTypeForm, PhoneTypeFormLabel),
    mlsPhoneType: getOptions(MlsPhoneTypeForm, MlsPhoneTypeFormLabel),
  };
  return options;
};
