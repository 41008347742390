import { GridResponse } from "@homesusa/grid-table";
import { RecordSummary } from "@homesusa/record";
import axios from "axios";
import { SavedChangesFilter } from "core/interfaces";
import { CompanySavedChanges } from "core/interfaces/company-saved-changes.interface";
const apiUrl = `${process.env.REACT_APP_API_URL}api/`;
const getCompanySavedChanges = async (
  params?: SavedChangesFilter
): Promise<GridResponse<CompanySavedChanges>> => {
  return axios
    .get<GridResponse<CompanySavedChanges>>(`${apiUrl}company-history`, {
      params,
    })
    .then((response) => response.data);
};
const getCompanySummary =
  () =>
  async (companyHistoryId: string): Promise<RecordSummary> => {
    const response = await axios.get(
      `${apiUrl}company-history/${companyHistoryId}/summary`
    );
    return Promise.resolve(response.data as RecordSummary);
  };

const getCompanySummaryRoute = (
  companyId: string,
  companyHistoryId: string
): string => `../${companyId}/company-saved-changes/${companyHistoryId}`;

export { getCompanySavedChanges, getCompanySummary, getCompanySummaryRoute };
