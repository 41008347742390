import React from "react";

import { AppContext } from "@homesusa/layout";
import {
  FormContext,
  isNullOrEmpty,
  isStringNullOrEmpty,
} from "@homesusa/form";
import { createService } from "core/services/companies/company-service.service";
import { CompanyContext, ServiceFormContext } from "../contexts";
import { useServiceFormHasErrors } from "./form/use-service-form-has-errors";

export const useCreateService = (): (() => Promise<boolean>) => {
  const { company, getServices, getCompany } = React.useContext(CompanyContext);
  const { formState } = React.useContext<ServiceFormContext>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const hasError = useServiceFormHasErrors(formState);

  const onSave = async (): Promise<boolean> => {
    if (hasError()) {
      return Promise.resolve(false);
    }
    return createService(company.id, formState).then(() => {
      addAlert({
        message: "The service was successfully created",
        variant: "success",
      });

      getServices({});
      if (
        !isNullOrEmpty(formState.xmlBuilderNumbers) ||
        !isStringNullOrEmpty(formState.xmlCorporateBuilderNumber)
      ) {
        getCompany();
      }
      return Promise.resolve(true);
    });
  };

  return onSave;
};
