import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import {
  FormContext,
  FormContextActions,
  PhoneInput,
  Select,
  formContexErrorReducer,
  formContextReducer,
  FormMode,
  FormRules,
} from "@homesusa/form";
import { SectionBox } from "core/components";
import { Phones } from "core/interfaces";
import { GridElementContext } from "modules/company/contexts";

export const ContactPhoneForm = ({
  initialData,
  onSuccess,
  onSave,
  typePhones,
}: {
  initialData?: Phones;
  onSave: (phoneState: Phones) => void;
  onSuccess: () => void;
  typePhones: Record<string, never>;
}): JSX.Element => {
  const { isEdit } = React.useContext(GridElementContext);
  // Form state
  const [phoneState, phoneDispatch] = React.useReducer<
    (state: Phones, action: FormContextActions) => Phones
  >(formContextReducer, initialData ?? Object.assign({}));
  // Form errors
  const [formErrors, formErrorsDispatch] = React.useReducer(
    formContexErrorReducer,
    Object.assign({})
  );
  const { formMode } = React.useContext(FormContext);

  const formRules: FormRules = React.useMemo(
    () =>
      Object.assign({
        number: {
          label: "Phone",
          required: true,
        },
        phoneType: {
          label: "Type",
          required: true,
          options: typePhones,
        },
      }),
    []
  );

  const disableButton = React.useMemo(
    (): boolean =>
      phoneState.number &&
      phoneState.phoneType &&
      Object.keys(formErrors ?? {}).length === 0
        ? false
        : true,
    [phoneState]
  );

  return (
    <FormContext.Provider
      value={{
        formState: phoneState,
        formDispatch: phoneDispatch,
        formMode: formMode,
        formErrors,
        formRules,
        formErrorsDispatch,
        otherProps: {},
      }}
    >
      <SectionBox title="Phones">
        <Grid container>
          <Grid item xs={6}>
            <PhoneInput name="number" />
          </Grid>
          <Grid item xs={6}>
            <Select name="phoneType" />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="caption" color="initial">
            Fill in the requested fields and press the {isEdit ? "edit" : "add"}{" "}
            button.
          </Typography>
          <Button
            disabled={disableButton || formMode === FormMode.ReadOnly}
            onClick={(): void => {
              onSave(phoneState);
              phoneDispatch({ type: "OverrideState", payload: {} });
              formErrorsDispatch({ type: "Clean" });
              onSuccess();
            }}
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </Box>
      </SectionBox>
    </FormContext.Provider>
  );
};
