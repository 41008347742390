import React from "react";
import { MlsEntityContext } from "../contexts";
import { MlsEntityDataForm } from "core/interfaces/mls-entity-data-form.interface";
import { AuthCompanyContext } from "@homesusa/auth";
import { getMlsEntity } from "core/services/mls-entity/mls-entity.service";
import { LinearProgress } from "@mui/material";

export function MlsEntityProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const [mlsEntity, setMlsEntity] = React.useState<MlsEntityDataForm>();

  const fetchData = React.useCallback(() => {
    getMlsEntity(currentMarket).then((data: MlsEntityDataForm) => {
      setMlsEntity(data);
    });
  }, [currentMarket]);

  React.useEffect(() => fetchData(), [fetchData]);

  if (!mlsEntity) {
    return <LinearProgress />;
  }

  return (
    <MlsEntityContext.Provider
      value={{
        mlsEntity: mlsEntity,
        getMlsEntity: fetchData,
      }}
    >
      {children}
    </MlsEntityContext.Provider>
  );
}
