import { Typography, Grid } from "@mui/material";

import {
  FormStyled,
  Input,
  NumberInput,
  Select,
  RichTextEditor,
} from "@homesusa/form";
import { AnimatedContainer, SectionBox } from "@homesusa/core";

function MlsEntityForm(): JSX.Element {
  return (
    <AnimatedContainer>
      <FormStyled>
        <Typography sx={{ width: "100%", my: 3 }} variant="h2">
          Mls Entity
        </Typography>
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <Grid container columnGap={3} wrap="nowrap">
              <Grid item xs={6}>
                <SectionBox title="General">
                  <Grid container>
                    <Grid item xs={12}>
                      <Input name="name" />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="URL"
                        name="url"
                        tooltip="The URL must contain http(s)://"
                      />
                    </Grid>
                  </Grid>
                </SectionBox>
              </Grid>
              <Grid item xs={6}>
                <SectionBox title="Address">
                  <Grid container>
                    <Grid item xs={6}>
                      <NumberInput name="streetNum" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input name="streetName" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input name="city" />
                    </Grid>
                    <Grid item xs={6}>
                      <Select name="state" />
                    </Grid>
                    <Grid item xs={6}>
                      <NumberInput name="zip" />
                    </Grid>
                  </Grid>
                </SectionBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SectionBox title="Mls Information">
              <Grid container>
                <Grid item xs={12}>
                  <RichTextEditor
                    label="Information"
                    name="mlsInformation"
                    maxLength={6000}
                  />
                </Grid>
              </Grid>
            </SectionBox>
          </Grid>
        </Grid>
      </FormStyled>
    </AnimatedContainer>
  );
}

export default MlsEntityForm;
