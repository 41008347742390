import React, { useState } from "react";
import { Column } from "react-table";
import { LinearProgress } from "@mui/material";

import {
  FetchData,
  GridProvider,
  GridTable,
  GridToolbar,
} from "@homesusa/grid-table";
import {
  CompanyServiceCode,
  CompanyServiceCodeLabel,
  Format,
  PersistentRightModal,
} from "@homesusa/core";
import { useHasRole } from "@homesusa/auth";
import { Service } from "core/interfaces";
import { FeeFrequencyLabel } from "core/enums";
import { CompanyContext } from "modules/company/contexts";
import { AnimatedContainer } from "core/components";
import { DeleteServiceButton } from "./delete-button.component";
import { CompanyServicesForm } from "./form";

export const CompanyServicesGrid = (): JSX.Element => {
  const {
    services,
    getServices,
    company: { id },
  } = React.useContext(CompanyContext);
  const [serviceId, setServiceId] = useState<string>();
  const [showFormModal, setShowFormModal] = React.useState(false);
  const [serviceCode, setServiceCode] = React.useState<string[]>(
    Object.values(CompanyServiceCode)
  );
  const { isMlsAdministrator } = useHasRole();

  const columns: Array<Column<Service>> = React.useMemo(() => {
    const cols: Array<Column<Service>> = [
      {
        Header: "Row Number",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "Code",
        accessor: (data: Service): string =>
          CompanyServiceCodeLabel.get(data.serviceCode) ?? data.serviceCode,
      },
      {
        Header: "Price",
        accessor: (data: Service): string => Format.Money(data.price),
      },
      {
        Header: "Fee Frequency",
        accessor: (data: Service): string =>
          FeeFrequencyLabel.get(data.feeFrequency) ?? data.feeFrequency,
      },
      {
        Header: "Created",
        accessor: (data: Service): string => Format.DateTime(data.sysCreatedOn),
      },
    ];

    if (isMlsAdministrator) {
      cols.push({
        Header: "Actions",
        disableSortBy: true,
        accessor: (data: Service): string => data.id,
        Cell: function Actions({ value }: { value: string }): JSX.Element {
          return <DeleteServiceButton serviceId={value} isIconButton />;
        },
      });
    }
    return cols;
  }, [isMlsAdministrator]);

  /* methods */
  const handleCloseFormModal = (value: boolean): void => {
    setServiceId(undefined);
    setShowFormModal(value);
  };

  const handleEditService = (serviceId: string): void => {
    setServiceId(serviceId);
    setShowFormModal(true);
  };

  React.useEffect(() => {
    getServices({ serviceCode });
  }, [serviceCode, getServices]);

  const fetchData = React.useCallback(
    async (props: FetchData): Promise<void> => {
      await getServices(props);
    },
    [getServices]
  );

  const fetchDataForDownloading = React.useCallback(async (): Promise<
    Service[]
  > => {
    const result = await getServices({ isForDownloading: true });
    return result.data;
  }, [getServices]);

  if (!services) {
    return <LinearProgress />;
  }

  return (
    <AnimatedContainer>
      <GridProvider
        options={{ columns, data: services.data }}
        totalRows={services?.total}
        fetchData={fetchData}
      >
        <GridToolbar>
          {isMlsAdministrator && (
            <GridToolbar.AddButton
              onClick={(): void => setShowFormModal(true)}
            />
          )}
          <GridToolbar.ExportButtons
            onClick={fetchDataForDownloading}
            fileName="services"
          />
          <GridToolbar.MultiSelectFilter
            defaultValue={serviceCode}
            onChange={(value): void => setServiceCode(value)}
            options={Object.values(CompanyServiceCode).reduce(
              (previusValue, currentCode) => ({
                ...previusValue,
                [currentCode.toString()]:
                  CompanyServiceCodeLabel.get(currentCode),
              }),
              {}
            )}
            label="ServiceCode"
          />
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <GridTable
          onRowClick={({ row }: { row: Service }): void =>
            handleEditService(row.id)
          }
        />
      </GridProvider>
      <PersistentRightModal
        title="New service subscription"
        open={showFormModal}
        onChange={handleCloseFormModal}
      >
        <CompanyServicesForm companyId={id} serviceId={serviceId} />
      </PersistentRightModal>
    </AnimatedContainer>
  );
};
