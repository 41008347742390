import React from "react";

import { AppContext } from "@homesusa/layout";
import { AuthCompanyContext } from "@homesusa/auth";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { upsertMlsEntity } from "core/services/mls-entity/mls-entity.service";
import { MlsEntityFormContextProps } from "../contexts/mls-entity-form";
import { MlsEntityContext } from "../contexts";

export const useUpdateMlsEntity = (): (() => void) => {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const { getMlsEntity } = React.useContext(MlsEntityContext);
  const { formState } =
    React.useContext<MlsEntityFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);

  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    upsertMlsEntity(currentMarket, formState).then(() => {
      addAlert({
        message: "The request was successfully updated",
        variant: "success",
      });
      getMlsEntity(currentMarket);
    });
    return Promise.resolve();
  };

  return onSave;
};
