import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules, zipCodeRule } from "@homesusa/form";
import { StateType, StateTypeLabel } from "core/enums";

export const mlsEntityRules: FormRules = {
  name: {
    label: "Name",
    required: true,
  },
  streetNum: {
    label: "Street Number",
    required: true,
  },
  streetName: {
    label: "Street Name",
    required: true,
  },
  city: {
    label: "City",
    required: true,
  },
  state: {
    label: "State",
    required: true,
    options: getOptionsFromEnumAndMap(Object.values(StateType), StateTypeLabel),
  },
  zip: {
    ...zipCodeRule,
    required: true,
  },
  url: {
    label: "URL",
    required: true,
    regex: `^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`,
    regexMessage: "Please provide a valid URL.",
  },
  mlsInformation: {
    label: "MLS Information",
    required: true,
    maxLength: 6000,
  },
};
