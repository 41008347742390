export enum FeeFrequency {
  Weekly = "weekly",
  Monthly = "monthly",
  Quarterly = "quarterly",
  Yearly = "yearly",
  PerListing = "perListing",
}

export const FeeFrequencyLabel = new Map<FeeFrequency, string>([
  [FeeFrequency.Weekly, "Weekly"],
  [FeeFrequency.Monthly, "Monthly"],
  [FeeFrequency.Quarterly, "Quarterly"],
  [FeeFrequency.Yearly, "Yearly"],
  [FeeFrequency.PerListing, "Per Listing"],
]);
