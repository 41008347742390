import React from "react";

import { GridTable, GridProvider, Columns } from "@homesusa/grid-table";
import { SummaryField, RowsViewer } from "@homesusa/record";
import { Phones } from "core/interfaces";

type PhoneSummaryField = SummaryField<Phones>;

export function PhoneGrid<
  Tenum extends string,
  TLabelMap extends Map<Tenum, string>
>({
  phones,
  phoneTypeMap,
}: {
  phones: PhoneSummaryField[];
  phoneTypeMap: TLabelMap;
}): JSX.Element {
  const columns = React.useMemo(() => {
    const columns: Columns<PhoneSummaryField> = [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "Number",
        accessor: (data: PhoneSummaryField): JSX.Element => (
          <RowsViewer
            item={data}
            displayValue={(item: Phones): string => item.number}
          />
        ),
      },
      {
        Header: "Type",
        accessor: (data: PhoneSummaryField): JSX.Element => (
          <RowsViewer
            item={data}
            displayValue={(item: Phones): string =>
              phoneTypeMap.get(item.phoneType as Tenum) ?? item.phoneType
            }
          />
        ),
      },
    ];
    return columns;
  }, []);

  return (
    <GridProvider
      pageSize={10}
      options={{ columns, data: phones, manualPagination: false }}
    >
      <GridTable />
    </GridProvider>
  );
}
