import { useParams } from "react-router-dom";

import { NewEmployeeForm } from "../components/employee";
import { CompanyProvider } from "../providers";

function CompanyAddUser(): JSX.Element {
  const params = useParams<{ id: string }>();

  if (params.id == null) {
    return <></>;
  }

  return (
    <CompanyProvider companyId={params.id}>
      <NewEmployeeForm />
    </CompanyProvider>
  );
}

export default CompanyAddUser;
