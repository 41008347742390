import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowCircleUp, ArrowCircleDown } from "@mui/icons-material";

import { SubmitIconButton } from "@homesusa/core";
import { CompanySavedChangesContext } from "modules/company-history/contexts";
import { getCompanySummaryRoute } from "core/services/companies/company-saved-changes.service";

export function ShowNavigationRequestButton(): JSX.Element {
  const { savedChanges, companyHistoryId, companyId } = React.useContext(
    CompanySavedChangesContext
  );
  const navigate = useNavigate();
  const indexSelected = React.useMemo(
    () => savedChanges?.data?.findIndex((x) => x.id == companyHistoryId),
    [savedChanges]
  );

  const nextId = React.useMemo(
    () =>
      savedChanges?.total != 0 &&
      indexSelected != undefined &&
      indexSelected > 0
        ? savedChanges?.data[indexSelected - 1].id
        : null,
    [savedChanges, indexSelected]
  );

  const previousId = React.useMemo(
    () =>
      savedChanges &&
      indexSelected != undefined &&
      indexSelected + 1 < savedChanges.total
        ? savedChanges.data[indexSelected + 1].id
        : null,
    [savedChanges, indexSelected]
  );

  return (
    <Fragment>
      <SubmitIconButton
        title="Previous"
        buttonProps={{
          disabled: previousId == null,
        }}
        onClick={async (): Promise<void> => {
          navigate(getCompanySummaryRoute(companyId, previousId ?? ""));
        }}
      >
        <ArrowCircleDown />
      </SubmitIconButton>
      <SubmitIconButton
        title="Next"
        buttonProps={{
          disabled: nextId == null,
        }}
        onClick={async (): Promise<void> => {
          navigate(getCompanySummaryRoute(companyId, nextId ?? ""));
        }}
      >
        <ArrowCircleUp />
      </SubmitIconButton>
    </Fragment>
  );
}
