import { FormRules } from "@homesusa/form";

export const getServiceRules = (xmlRequired: boolean): FormRules => ({
  serviceCode: {
    label: "Service",
    required: true,
  },
  feeFrequency: {
    label: "Plan Type",
  },
  price: {
    label: "Price",
    required: true,
  },
  xmlBuilderNumbers: {
    label: "XML Builder Number",
    required: xmlRequired,
  },
  xmlCorporateBuilderNumber: {
    label: "XML Corporation Number",
    required: xmlRequired,
  },
});
