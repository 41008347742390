import React from "react";
import { Box } from "@mui/material";

import { FormContext, FormMode } from "@homesusa/form";
import { isValidGuid, PersistentRightModalContext } from "@homesusa/core";
import { SubmitButton } from "@homesusa/layout";
import { useHasRole } from "@homesusa/auth";
import { useCreateService, useUpdateService } from "modules/company/hooks";
import { ServiceFormContext } from "modules/company/contexts";
import { DeleteServiceButton } from "../delete-button.component";

export const CompanyServicesFormButtons = (): JSX.Element => {
  const { isMlsAdministrator } = useHasRole();
  const onSave = useCreateService();
  const onUpdate = useUpdateService();
  const { formState, formMode, changeFormMode } =
    React.useContext<ServiceFormContext>(FormContext);
  const { setShowRightModal } = React.useContext(PersistentRightModalContext);

  const isNewServices = React.useMemo(
    () => !isValidGuid(formState.id),
    [formState.id]
  );

  React.useEffect(() => {
    if (changeFormMode) {
      changeFormMode(isMlsAdministrator ? FormMode.Update : FormMode.ReadOnly);
    }
  }, [isMlsAdministrator]);

  const readOnly = formMode == FormMode.ReadOnly;

  const handleSubmitData = async (): Promise<void> => {
    const onSubmit = isNewServices ? onSave : onUpdate;
    const submitSuccess = await onSubmit();

    if (submitSuccess) {
      setShowRightModal(false);
    }
  };

  if (readOnly) {
    return <></>;
  }

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        columnGap: 2,
        justifyContent: "center",
      }}
    >
      <SubmitButton onClick={handleSubmitData}>Save</SubmitButton>
      {!isNewServices && (
        <DeleteServiceButton
          serviceId={formState.id}
          onClose={(): void => setShowRightModal(false)}
        />
      )}
    </Box>
  );
};
