import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import {
  FormContext,
  FormContextActions,
  Select,
  formContexErrorReducer,
  formContextReducer,
  FormMode,
  Input,
  FormRules,
} from "@homesusa/form";
import { SectionBox } from "core/components";
import { GridElementContext } from "modules/company/contexts";
import { MarketProspectingCredentials } from "core/interfaces/reverse-prospect.inerface";

export const ReverseProspectForm = ({
  initialData,
  onSuccess,
  onSave,
  marketCodeTypes,
}: {
  initialData?: MarketProspectingCredentials | undefined;
  onSave: (reverseProspectState: MarketProspectingCredentials) => void;
  onSuccess: () => void;
  marketCodeTypes: Record<string, never>;
}): JSX.Element => {
  const { isEdit } = React.useContext(GridElementContext);
  // Form state
  const [reverseProspectState, phoneDispatch] = React.useReducer<
    (
      state: MarketProspectingCredentials,
      action: FormContextActions
    ) => MarketProspectingCredentials
  >(formContextReducer, initialData ?? Object.assign({}));
  // Form errors
  const [formErrors, formErrorsDispatch] = React.useReducer(
    formContexErrorReducer,
    Object.assign({})
  );

  const disableButton = React.useMemo(
    (): boolean =>
      reverseProspectState.userName &&
      reverseProspectState.password &&
      reverseProspectState.marketCode &&
      Object.keys(formErrors ?? {}).length === 0
        ? false
        : true,
    [reverseProspectState]
  );

  const formRules: FormRules = React.useMemo(
    () =>
      Object.assign({
        userName: {
          label: "Username",
          required: true,
        },
        password: {
          label: "Password",
          required: true,
        },
        marketCode: {
          label: "Market",
          required: true,
          options: marketCodeTypes,
        },
      }),
    []
  );

  return (
    <FormContext.Provider
      value={{
        formState: reverseProspectState,
        formDispatch: phoneDispatch,
        formMode: FormMode.Update,
        formErrors,
        formRules,
        formErrorsDispatch,
        otherProps: {},
      }}
    >
      <SectionBox title="Reverse Prospect">
        <Grid container>
          <Grid item xs={6}>
            <Input name="userName" />
          </Grid>
          <Grid item xs={6}>
            <Input name="password" />
          </Grid>
          <Grid item xs={6}>
            <Select name="marketCode" />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="caption" color="initial">
            Fill in the requested fields and press the {isEdit ? "edit" : "add"}{" "}
            button.
          </Typography>
          <Button
            disabled={disableButton}
            onClick={(): void => {
              onSave(reverseProspectState);
              phoneDispatch({ type: "OverrideState", payload: {} });
              formErrorsDispatch({ type: "Clean" });
              onSuccess();
            }}
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </Box>
      </SectionBox>
    </FormContext.Provider>
  );
};
