import { Box } from "@mui/system";

export const LabelSections = ({
  icon,
  text,
}: {
  icon?: JSX.Element;
  text: string;
}): JSX.Element => (
  <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
    {icon}
    {text}
  </Box>
);
