import { FormRules } from "@homesusa/form";

export const getPhotographyServiceRules = (required: boolean): FormRules => ({
  stillHome: {
    label: "Stills - Home",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  stillExteriorOnly: {
    label: "Stills - Exterior only",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  twilight: {
    label: "3 photos",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  virtualTour: {
    label: "Virtual Tour",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  virtualStagingOneImage: {
    label: "1 image",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  virtualStagingThreeImage: {
    label: "3 image",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  virtualStagingSixImage: {
    label: "6 image",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  communityOptionOne: {
    label: "Option 1",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  communityOptionTwo: {
    label: "Option 2",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  communityOptionThree: {
    label: "Option 3",
    required: required,
    numberFormat: {
      prefix: "$",
    },
  },
  tripCharge: {
    label: "Trip Charge",
    required: required,
    numberFormat: {
      min: 10,
      max: 200,
      prefix: "$",
    },
  },
  photoshopEditing: {
    label: "Photoshop Editing",
    required: required,
    numberFormat: {
      prefix: "$",
      thousandSeparator: true,
      decimalScale: 2,
    },
  },
  isFirstSetIncluded: {
    label: "First Set of Stills is NOT Included",
  },
  photographyServicesEnabled: {
    label: "Enable Photography Services",
  },
});
