import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { Company, CompanyDataForm, RequestFilter } from "core/interfaces";

const apiUrl = `${process.env.REACT_APP_API_URL}api/`;

const getActiveCompanies = async (
  params: RequestFilter & {
    type?: string[];
  }
): Promise<GridResponse<Company>> => {
  const { type, ...restParams } = params;
  const response = await axios.get<GridResponse<Company>>(
    `${apiUrl}companies`,
    {
      params: {
        ...restParams,
        type,
      },
    }
  );
  return response.data;
};

const createCompany = async (company: CompanyDataForm): Promise<string> => {
  const response = await axios.post(`${apiUrl}companies`, company);
  return response.data;
};

const getCompany = async (id: string): Promise<CompanyDataForm> => {
  const response = await axios.get(`${apiUrl}companies/${id}`);
  return response.data as CompanyDataForm;
};

const updateCompany = async (
  company: CompanyDataForm
): Promise<CompanyDataForm> => {
  const { id, ...companyDataForm } = company;
  const response = await axios.put(`${apiUrl}companies/${id}`, companyDataForm);
  return response.data;
};

const deleteCompany = async (id: string): Promise<void> =>
  axios.delete(`${apiUrl}companies/${id}`).then(() => {
    return Promise.resolve();
  });

const uploadLogo = async (companyId: string, file: Blob): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios
    .post(`${apiUrl}companies/${companyId}/media`, formData, config)
    .then((response) => response.data);
};

export {
  getCompany,
  createCompany,
  updateCompany,
  getActiveCompanies,
  deleteCompany,
  uploadLogo,
};
