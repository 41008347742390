import React from "react";
import { FormProvider } from "@homesusa/form";
import { MlsEntityDataForm } from "core/interfaces/mls-entity-data-form.interface";
import { MlsEntityFormActions } from "../contexts/mls-entity-form";
import { mlsEntityFormReducer } from "../contexts/mls-entity-form/mls-entity-form.reducers";
import { mlsEntityRules } from "../rules/mls-entity-rules";

function MlsEntityFormProvider({
  initialData,
  children,
}: {
  children: React.ReactNode;
  initialData?: MlsEntityDataForm;
}): JSX.Element {
  return (
    <FormProvider<MlsEntityDataForm, MlsEntityFormActions>
      initialFormState={
        initialData ??
        Object.assign({
          state: "TX",
        })
      }
      formReducer={mlsEntityFormReducer}
      initialFormRules={mlsEntityRules}
    >
      {children}
    </FormProvider>
  );
}

export { MlsEntityFormProvider };
