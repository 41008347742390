import React from "react";
import { Save } from "@mui/icons-material";

import { FormContext } from "@homesusa/form";
import { SubmitButton } from "@homesusa/layout";
import { PersistentRightModalContext } from "@homesusa/core";
import { FormCreateBtnGroupStyled } from "core/styles";
import { useAddEmployees } from "../../hooks";
import { useEmployeeFormHasErrors } from "modules/company/hooks/form";

export function CreateEmployeeButton(): JSX.Element {
  const { setShowRightModal } = React.useContext(PersistentRightModalContext);
  const { formState } = React.useContext(FormContext);
  const hasErrors = useEmployeeFormHasErrors();
  const onSave = useAddEmployees();
  return (
    <FormCreateBtnGroupStyled>
      <SubmitButton
        onClick={(): Promise<void> =>
          onSave([formState]).then(() => setShowRightModal(false))
        }
        buttonProps={{
          startIcon: <Save />,
          disabled: hasErrors,
        }}
      >
        SAVE
      </SubmitButton>
    </FormCreateBtnGroupStyled>
  );
}
