import { useHasRole } from "@homesusa/auth";
import { ForbiddenError } from "@homesusa/core";
import MlsEntityFormPanel from "../components/mls-entity-panel.component";
import { MlsEntityProvider } from "../providers";

function MlsEntity(): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  if (!isMlsAdministrator) {
    return <ForbiddenError />;
  }

  return (
    <MlsEntityProvider>
      <MlsEntityFormPanel />
    </MlsEntityProvider>
  );
}

export default MlsEntity;
