import React from "react";
import { Column } from "react-table";
import { Format } from "@homesusa/core";
import { Corporation } from "../interfaces";

type corporationColumn = Array<Column<Corporation>>;

export const useCorporationGridColumns = (): corporationColumn => {
  const columns: corporationColumn = React.useMemo(
    () => [
      {
        Header: "Row Number",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      },
      {
        Header: "Name",
        accessor: (data: Corporation): string => data.name,
      },
      {
        Header: "Address",
        accessor: (data: Corporation): string => data.address,
      },
      {
        Header: "City",
        accessor: (data: Corporation): string => data.city,
      },
      {
        Header: "Web Url",
        accessor: (data: Corporation): string => data.webUrl,
      },
      {
        Header: "Created",
        accessor: (data: Corporation): string =>
          Format.DateTime(data.sysCreatedOn),
      },
    ],
    []
  );
  return columns;
};
