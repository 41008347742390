import React from "react";

import { CorporationDataForm } from "core/interfaces";
import { FormProvider } from "@homesusa/form";
import { CorporationFormActions } from "../contexts/corporation-form/corporation-form-actions.type";
import { companyFormReducer } from "../contexts/corporation-form/corporation-form.reducer";
import { corporationRules } from "../rules/corporation-rules";

function CorporationFormProvider({
  initialData,
  children,
}: {
  children: React.ReactNode;
  initialData?: CorporationDataForm;
}): JSX.Element {
  return (
    <FormProvider<CorporationDataForm, CorporationFormActions>
      initialFormState={
        initialData ??
        Object.assign({
          state: "TX",
        })
      }
      formReducer={companyFormReducer}
      initialFormRules={corporationRules}
    >
      {children}
    </FormProvider>
  );
}

export { CorporationFormProvider };
