import React from "react";
import { Column } from "react-table";
import { MarketProspectingCredentials } from "core/interfaces";
import { GridElementContext } from "modules/company/contexts";
import { IconButtonStyled } from "core/styles";
import { Delete } from "@mui/icons-material";
import { MarketCode, MarketCodeLabel } from "@homesusa/core";

type columnReverseProspect = Array<Column<MarketProspectingCredentials>>;
export const useReverseProspectColumns = (): columnReverseProspect => {
  const { onDelete } = React.useContext(GridElementContext);
  const columns: Array<Column<MarketProspectingCredentials>> = [
    {
      Header: "Market",
      accessor: (data: MarketProspectingCredentials): string =>
        MarketCodeLabel.get(data.marketCode as MarketCode) ?? data.marketCode,
    },
    {
      Header: "Username",
      accessor: (data: MarketProspectingCredentials): string => data.userName,
    },
    {
      Header: "Password",
      disableSortBy: true,
      accessor: (data: MarketProspectingCredentials): string =>
        "*".repeat(data.password.length),
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: (
        data: MarketProspectingCredentials
      ): MarketProspectingCredentials => data,
      Cell: function Actions({ row }: { row: { index: number } }): JSX.Element {
        return (
          <div>
            <IconButtonStyled
              title="Delete"
              onClick={(e): void => {
                e.stopPropagation();
                onDelete(row.index);
              }}
            >
              <Delete />
            </IconButtonStyled>
          </div>
        );
      },
    },
  ];

  return columns;
};
