import React from "react";

import { FormSubTabs } from "@homesusa/form";
import {
  BusinessCenter,
  CorporateFare,
  PermMedia,
  Storage,
} from "@mui/icons-material";
import FormGeneralTab from "./general/general.component";
import FormMediaTab from "./media/media.component";
import FormTransferTab from "./tranfer.component";
import BrokerComponent from "./broker.component";
import { useUserProfile } from "@homesusa/auth";

export function FormCompaniesTabs(): JSX.Element {
  const userProfile = useUserProfile();

  const subTabs = React.useMemo(() => {
    return {
      information: {
        label: "Information",
        node: <FormGeneralTab />,
        icon: <CorporateFare />,
      },
      media: {
        label: "Photo Request",
        node: <FormMediaTab />,
        icon: <PermMedia />,
      },
      ftp: {
        label: "FTP",
        node: <FormTransferTab />,
        icon: <Storage />,
      },
      ...(userProfile?.isSuperAdmin && {
        broker: {
          label: "Uploader/Broker Information",
          node: <BrokerComponent />,
          icon: <BusinessCenter />,
        },
      }),
    };
  }, [userProfile]);

  if (userProfile) {
    return <FormSubTabs tabs={subTabs} />;
  }

  return <></>;
}
