import React from "react";
import { Column } from "react-table";
import { Delete } from "@mui/icons-material";
import { Format } from "@homesusa/core";
import { Phones } from "core/interfaces";
import { IconButtonStyled } from "core/styles";
import { GridElementContext } from "../contexts";
import { PhoneTypeForm, PhoneTypeFormLabel } from "core/enums";

type columnPhones = Array<Column<Phones>>;
export const useContactPhonesColumns = (readOnly: boolean): columnPhones => {
  const { onDelete } = React.useContext(GridElementContext);

  const columns: Array<Column<Phones>> = [
    {
      Header: "Phone",
      accessor: (data: Phones): string => Format.PhoneNumber(data.number),
    },
    {
      Header: "Phone Type",
      accessor: (data: Phones): string =>
        PhoneTypeFormLabel.get(
          PhoneTypeForm[data.phoneType as PhoneTypeForm]
        ) ?? data.phoneType,
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: (data: Phones): Phones => data,
      Cell: function Actions({ row }: { row: { index: number } }): JSX.Element {
        return (
          <div>
            <IconButtonStyled
              title="Delete"
              onClick={(e): void => {
                e.stopPropagation();
                onDelete(row.index);
              }}
              disabled={readOnly}
            >
              <Delete />
            </IconButtonStyled>
          </div>
        );
      },
    },
  ];

  return columns;
};
